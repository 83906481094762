<template>
  <div class="with-nav-bar">
    <van-nav-bar
      title="设备详情"
      left-text="返回"
      :right-text="!state.loading ? '更新' : ''"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
      @click-right="
        !state.loading &&
          $router.push({ name: 'DeviceForm', query: { id: state.id } })
      "
    />
    <van-loading v-if="state.loading" class="mt-3 text-center" vertical
      >加载中</van-loading
    >
    <div v-else>
      <van-cell title="所属电站" :value="state.station_name" />
      <van-cell title="设备名称" :value="state.name">
        <template #extra>
          <van-tag v-if="!state.offline" type="success" class="ml-2" plain
            >在线</van-tag
          >
          <van-tag v-else class="ml-2" plain>离线</van-tag>
        </template>
      </van-cell>
      <van-cell title="设备编码" :value="state.pile_code" />
      <van-cell title="设备厂家" :value="state.provider" />
      <van-cell title="固件版本" :value="state.software_version" />
      <van-cell title="功率类型" :value="`${state.gun_type} ${state.power}`" />
      <van-cell
        title="充电枪数量"
        :value="state.gun_num"
        is-link
        :to="{ name: 'DeviceGun', query: { id: state.id } }"
      />
      <van-cell title="SIM ICCID" :value="state.iccid" />
      <van-cell title="IP地址" :value="state.ip_address" />
      <van-cell title="MAC地址" :value="state.MAC" />
      <van-cell title="绑定时间" :value="state.last_bindtime" />
      <van-cell title="上次签到" :value="state.last_checkin" />
      <van-cell title="上次心跳" :value="state.last_heartbeat" />
      <div class="py-4 action-button-area text-center">
        <van-button type="danger" @click="unbind">解绑</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Dialog, Field, Popup, Picker, Toast, Tag } from "vant";
import { loadDevice, unbindDevice } from "@/api/device.service";
export default {
  name: "StationDetail",
  components: {
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Tag.name]: Tag,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      id: 0,
      name: "",
      iccid: "",
      pile_code: "",
      power: "",
      provider: "",
      station_name: "",
      ip_address: "",
      gun_num: "",
      gun_type: "",
      last_bindtime: "",
      last_checkin: "",
      last_heartbeat: "",
      scan_nearby: "开启",
      offline: 1,
      loading: true,
    });

    const getDevice = async (id) => {
      try {
        const { result } = await loadDevice(id);
        state.loading = false;
        state.id = result.id;
        state.name = result.name;
        state.iccid = result.iccid ? result.iccid : "/";
        state.pile_code = result.pile_code;
        state.power = `${result.power}kW`;
        state.provider = result.provider ? result.provider : "未知";
        state.station_name = result.station.name;
        state.ip_address = result.ip_address;
        state.gun_num = result.gun_num;
        state.gun_type = result.gun_type == 1 ? "直流电" : "交流电";
        state.last_bindtime = result.last_bindtime;
        state.ip_address = result.ip_address ? result.ip_address : "/";
        state.MAC = result.MAC ? result.MAC : "/";
        state.software_version = result.software_version
          ? result.software_version
          : "/";
        state.last_checkin = result.last_checkin ? result.last_checkin : "从未";
        state.last_heartbeat = result.last_heartbeat
          ? result.last_heartbeat
          : "从未";
        state.scan_nearby = result.scan_nearby == 0 ? "关闭" : "开启";
        state.offline = result.offline;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const unbind = () => {
      Dialog.confirm({
        title: "解除绑定",
        message: "请确认是否继续解除绑定?",
      })
        .then(() => {
          processUnbind();
        })
        .catch(() => {});
    };

    const processUnbind = async () => {
      Toast.loading({ message: "请稍后", duration: 0, forbidClick: true });
      try {
        await unbindDevice(state.id);
        setTimeout(() => {
          Toast.clear();
          router.back();
        }, 1500);
      } catch (error) {
        Toast.clear();
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    onMounted(() => {
      const { id } = route.query;
      if (!id) {
        Toast.fail("参数错误");
        router.back();
      } else {
        getDevice(id);
      }
    });

    return { state, getDevice, unbind };
  },
};
</script>
