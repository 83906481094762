import ApiService from "./api.service";

export function loadDevice(id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/device/getById", { id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function bindDevice(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/device/bind", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function unbindDevice(id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/device/unbind", { id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function updateDevice(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/device/update", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getDeviceList(agent_id = 0) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/device/listGroupByStation", {
      agent_id,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getUnbindDeviceList(agent_id = 0) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/device/listUnbindGroupByStation", {
      agent_id,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getGunList(device_id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/device/getGunList", {
      device_id,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

// 同步电费设置到充电桩
export function syncFeeSettings(id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/device/syncFeeSettings", { id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

// 获取充电前所需的所有参数, 包扩电站信息, 设备信息, 费率信息等等
export function getRechargeParams(pile_code, gun_number) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/device/getRechargeParams", {
      pile_code,
      gun_number,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

// 开始充电, 后端会检测是否有正在充电的订单, 设备和枪的状态, 余额是否足够...下订单
export function startPowerRecharge(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/device/startPowerRecharge", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

// 当前登录用户停止正在充电的订单
export function stopPowerRecharge(order_id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/device/stopPowerRecharge", { order_id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}
